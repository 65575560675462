import {
  ArrowRight,
  Check,
  ChevronLeft,
  ChevronRight,
  CircleHelp,
  ClipboardCheck,
  Copy,
  CreditCard,
  EllipsisVertical,
  File,
  FileText,
  Image,
  Laptop,
  LoaderCircle,
  Moon,
  Pizza,
  Plus,
  Settings,
  SunMedium,
  Trash,
  TriangleAlert,
  User,
  X,
} from "lucide-svelte"

import type { SvelteComponent } from "svelte"
import GitHub from "./github.svelte"
import Twitter from "./twitter.svelte"
import Hamburger from "./hamburger.svelte"
import LogoLight from "./logo-light.svelte"
import LogoDark from "./logo-dark.svelte"

export type Icon = SvelteComponent

export const Icons = {
  // logo: Logo,
  close: X,
  spinner: LoaderCircle,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  trash: Trash,
  post: FileText,
  page: File,
  media: Image,
  settings: Settings,
  billing: CreditCard,
  ellipsis: EllipsisVertical,
  add: Plus,
  warning: TriangleAlert,
  user: User,
  arrowRight: ArrowRight,
  help: CircleHelp,
  pizza: Pizza,
  twitter: Twitter,
  check: Check,
  copy: Copy,
  copyDone: ClipboardCheck,
  sun: SunMedium,
  moon: Moon,
  laptop: Laptop,
  gitHub: GitHub,
  Hamburger,
  LogoLight,
  LogoDark,
}
