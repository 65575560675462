<script lang="ts">
  import {
    Icons,
    MainNav,
    MobileNav,
    ModeToggle,
  } from "$lib/components/centralci"
  import { buttonVariants } from "$lib/components/ui/button/index.js"
  import { cn } from "$lib/utils.js"
  import type { Session } from "@supabase/supabase-js"
  import * as DropdownMenu from "$lib/components/ui/dropdown-menu"
  import Button from "$lib/components/ui/button/button.svelte"

  export let session: Session | null

  export const userUuid = session?.user?.id ?? null

  export const userEmailOrUuid = session?.user?.email ?? userUuid

  export const userHandle = userEmailOrUuid
    ? userEmailOrUuid.split("@", 1)
    : null

  console.log("session in site header", session)
</script>

<header
  class="border-border/40 bg-background/95 supports-[backdrop-filter]:bg-background/60 sticky top-0 z-50 w-full border-b backdrop-blur"
>
  <div class="container flex h-14 max-w-screen-2xl items-center">
    <MainNav />
    <MobileNav />
    <div class="grow"></div>
    <div class="flex items-center space-x-2 justify-end">
      <nav class="flex items-center">
        {#if userHandle}
          <DropdownMenu.Root>
            <DropdownMenu.Trigger>{userHandle}</DropdownMenu.Trigger>
            <DropdownMenu.Content>
              <DropdownMenu.Group>
                <DropdownMenu.Label>
                  <a href="/account/create_project">My Account</a>
                </DropdownMenu.Label>
                <DropdownMenu.Item>
                  <a href="/account/sign_out">Logout</a>
                </DropdownMenu.Item>
                <DropdownMenu.Item>
                  <a href="/account/select_plan">Manage Subscription</a>
                </DropdownMenu.Item>
              </DropdownMenu.Group>
            </DropdownMenu.Content>
          </DropdownMenu.Root>
        {:else}
          <Button
            class="gap-1 rounded-lg"
            href="/login/authenticate"
            variant="ghost"
          >
            Log In
          </Button>
          <Button class="gap-1 rounded-lg" href="/login/register">
            Register
          </Button>
        {/if}
        <a
          href="https://x.com/central_ci"
          class="ml-1.5"
          target="_blank"
          rel="noreferrer"
        >
          <div
            class={cn(
              buttonVariants({
                size: "sm",
                variant: "ghost",
              }),
              "h-8 w-8 px-0",
            )}
          >
            <Icons.twitter class="h-3 w-3 fill-current" />
            <span class="sr-only">X (formerly known as Twitter)</span>
          </div>
        </a>
        <ModeToggle />
      </nav>
    </div>
  </div>
</header>
