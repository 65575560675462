<script lang="ts">
  // import { siteConfig } from "$lib/config/site.js";
</script>

<!--<div class="flex-grow"></div>-->

<footer class="py-6 md:px-8 md:py-0">
  <div
    class="container flex flex-col items-center justify-between gap-4 md:h-24 md:flex-row"
  >
    <div
      class="flex flex-col items-center gap-4 px-8 md:flex-row md:gap-2 md:px-0"
    >
      <p
        class="text-muted-foreground text-center text-sm leading-loose md:text-left"
      >
        Need help? Contact our customer Success Team by emailing
        <a class="link link-hover my-1" href="mailto:success@centralci.com"
          >success@centralci.com</a
        >
      </p>
    </div>
  </div>
</footer>
