<script lang="ts">
  import { cubicInOut } from "svelte/easing"
  import { crossfade } from "svelte/transition"
  import { page } from "$app/stores"
  import { cn } from "$lib/utils.js"
  // import { ScrollArea } from "$lib/components/ui/scroll-area";
  import { Icons } from "$lib/components/centralci"

  const [send, receive] = crossfade({
    duration: 250,
    easing: cubicInOut,
  })

  export const pages = [
    {
      title: "Blog",
      href: "/blog",
    },
    {
      title: "Pricing",
      href: "/pricing",
    },
    {
      title: "Account",
      href: "/account",
    },
  ]
</script>

<div class="mr-4 hidden md:flex">
  <a href="/" class="mr-8 flex items-center space-x-2">
    <span class="block dark:hidden">
      <Icons.LogoLight />
    </span>
    <span class="hidden dark:block">
      <Icons.LogoDark />
    </span>
  </a>
  <nav class="flex items-center gap-6 text-sm">
    {#each pages as pagedef, index (index)}
      {@const isActive =
        $page.url.pathname.startsWith(pagedef.href) ||
        ($page.url.pathname === "/" && index === 0)}

      <a
        href={pagedef.href}
        data-sveltekit-noscroll
        class={cn(
          "hover:text-primary relative flex h-7 items-center justify-center rounded-full px-4 text-center text-sm transition-colors",
          isActive ? "text-primary font-medium" : "text-muted-foreground",
        )}
      >
        {#if isActive}
          <div
            class="bg-muted absolute inset-0 rounded-full"
            in:send={{ key: "activetab" }}
            out:receive={{ key: "activetab" }}
          ></div>
        {/if}
        <div class="relative">
          {pagedef.title}
        </div>
      </a>
    {/each}
  </nav>
</div>
